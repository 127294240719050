.loginLayout{
  min-height:100vh;
}
.login-form-button{
  width:100%;
}
.socialLoginButton{
  background:transparent;

}
.submit-form-button{
  width:50%;
}
